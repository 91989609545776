import antdBr from "antd/lib/locale-provider/pt_BR";
import appLocaleData from "react-intl/locale-data/br";
import ptMessages from "../locales/pt_BR.json";
import momentLocale from "util/locale";

const PtLang = {
  messages: {
    ...ptMessages
  },
  antd: antdBr,
  moment: momentLocale,
  locale: "br",
  data: appLocaleData
};
export default PtLang;
