import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {/*<AppsNavigation/>*/}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme="dark"
              mode="inline"
            >
              <Menu.Item key="home">
                <Link to="/home">
                  <i className="icon icon-dasbhoard" />
                  <IntlMessages id="sidebar.samplePage" />
                </Link>
              </Menu.Item>
              
              <Menu.Item key="campanhas">
                <Link to="/campanhas/">
                  <i className="icon icon-megaphone" />
                  <IntlMessages id="sidebar.campanhas" />
                </Link>
              </Menu.Item>
              <Menu.Item key="fechamentos">
                <Link to="/fechamentos/">
                  <i className="icon icon-switch" />
                  <IntlMessages id="sidebar.fechamentos" />
                </Link>
              </Menu.Item>
              <Menu.Item key="metas">
                <Link to="/metas/">
                  <i className="icon icon-default-timeline" />
                  <IntlMessages id="sidebar.metas" />
                </Link>
              </Menu.Item>
              <Menu.Item key="usuarios">
                <Link to="/usuarios/">
                  <i className="icon icon-user" />
                  <IntlMessages id="sidebar.usuarios" />
                </Link>
              </Menu.Item>
              <MenuItemGroup
                key="ecommerce"
                className="gx-menu-group"
                title="E-commerce"
              >
                <SubMenu
                  key="propostas_menu"
                  popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-pricing-table" />
                      <IntlMessages id="sidebar.propostas" />
                    </span>
                  }
                >
                  <Menu.Item key="propostas">
                    <Link to="/propostas">
                      <span>Listar</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="propostas/baixas">
                    <Link to="/propostas/baixas">
                      <span>Baixas</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="propostas/pendentes">
                    <Link to="/propostas/pendentes">
                      <span>Pendentes</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>

              <MenuItemGroup
                key="components"
                className="gx-menu-group"
                title="Salesforce"
              >
                <SubMenu
                  key="planos_menu"
                  popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-product-list" />
                      <span>Planos</span>
                    </span>
                  }
                >
                  <Menu.Item key="planos/pf">
                    <Link to="/planos/pf">
                      <i className="icon icon-user-o" />
                      <IntlMessages id="sidebar.planos.pf" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="planos/pme">
                    <Link to="/planos/pme">
                      <i className="icon icon-family" />
                      <IntlMessages id="sidebar.planos.pme" />
                    </Link>
                  </Menu.Item>                  
                </SubMenu>
              </MenuItemGroup>
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps)(SidebarContent);
