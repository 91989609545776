import React from "react";
import { Button, Form, Input } from "antd";
import { connect } from "react-redux";
import { recaptchaToken, appName } from "util/config";
import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import axios from "util/Api";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      captchaValidado: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  verifyCallback = (recaptchaToken) => {
    // console.log(recaptchaToken, "<= your recaptcha token")
    axios
      .post("auth/recaptcha", { recaptchaToken: recaptchaToken })
      .then(({ data }) => {
        if (data.status === true) {
          this.setState({ captchaValidado: true });
        } else {
          this.setState({ captchaValidado: false });
        }
      });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push("/");
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    loadReCaptcha(recaptchaToken);

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg"></div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signIn" />
                </h1>
                <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img
                  src="https://api.unimednatal.com.br/api/imgs/logos/logo2.png"
                  style={{ width: "100px" }}
                  alt="Unimed"
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("matricula", {
                    initialValue: null,
                    rules: [
                      {
                        required: true,
                        message: "matrícula inválida!",
                      },
                    ],
                  })(<Input placeholder="Matrícula" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    initialValue: "",
                    rules: [{ required: true, message: "Insira sua senha!" }],
                  })(
                    <Input
                      type="password"
                      placeholder="Senha"
                      autocomplete="current-password"
                    />
                  )}
                </FormItem>
                <div>
                  <ReCaptcha
                    sitekey={recaptchaToken}
                    action={appName + "_login"}
                    verifyCallback={this.verifyCallback}
                  />
                </div>
                <FormItem>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    disabled={!this.state.captchaValidado}
                  >
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { userSignIn })(WrappedNormalLoginForm);
