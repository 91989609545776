import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}home`}
        component={asyncComponent(() => import("./Home"))}
      />
      <Route
        path={`${match.url}campanhas`}
        component={asyncComponent(() => import("./Campanhas"))}
      />
      <Route
        path={`${match.url}propostas`}
        component={asyncComponent(() => import("./Propostas"))}
      />
      <Route
        path={`${match.url}relatorios`}
        component={asyncComponent(() => import("./Relatorios"))}
      />
      <Route
        path={`${match.url}fechamentos`}
        component={asyncComponent(() => import("./Fechamentos"))}
      />
      <Route
        path={`${match.url}metas`}
        component={asyncComponent(() => import("./Metas"))}
      />
      <Route
        path={`${match.url}planos`}
        component={asyncComponent(() => import("./Planos"))}
      />
      <Route
        path={`${match.url}banners`}
        component={asyncComponent(() => import("./Banners"))}
      />
      <Route
        path={`${match.url}documentacoes`}
        component={asyncComponent(() => import("./Documentacoes"))}
      />
      <Route
        path={`${match.url}panfletos`}
        component={asyncComponent(() => import("./Panfletos"))}
      />
      <Route
        path={`${match.url}red_carencias`}
        component={asyncComponent(() => import("./RedCarencias"))}
      />
      <Route
        path={`${match.url}usuarios`}
        component={asyncComponent(() => import("./Usuarios"))}
      />
    </Switch>
  </div>
);

export default App;
