import axios from "axios";
import { appName, codAplicacao, urlApi } from "util/config";

const instance = axios.create({
  baseURL: urlApi + "apps",
  headers: {
    "Content-Type": "application/json",
    AppName: appName,
    CodAplicacao: codAplicacao
  }
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
