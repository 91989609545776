const languageData = [
 {
    languageId: 'portuguese',
    locale: 'br',
    name: 'Português',
    icon: 'br'
  }

];
export default languageData;
